<template>
  <div class="password-validations" v-if="password">
    <div
      class="body-small text-gray-80"
      v-text="$translations['form']['password-security']"
    />
    <li
      class="body-small"
      :class="password.length >= 8 ? 'text-green' : 'text-gray-50'"
      v-text="$translations['form']['password-length']"
    />
    <li
      class="body-small"
      :class="validationRegex ? 'text-green' : 'text-gray-50'"
      v-text="$translations['form']['password-regex-rules']"
    />
  </div>
</template>

<script>
export default {
  name: 'PasswordValidation',
  props: {
    password: String,
    validationRegex: Boolean
  }
}
</script>

<style lang="sass" scoped>
.password-validations
  align-self: flex-start
  margin-left: 12px
  .body-small
    transition: .5s
</style>
