<template>
  <div class="sign-up">
    <!-- splash or logo area -->
    <div class="sign-up-logo">
      <img class="logo" src="@/assets/logo_with_name.svg" alt="Selia" />
    </div>

    <div class="sign-up-header">
      <div class="heading-big" v-text="$translations['sign-up'].continue" />
      <div class="sign-up-header-continue">
        <div
          class="body"
          v-text="$translations['sign-up']['sign-in-divider-text']"
        />
        <div
          @click="authChange('SignUp', 'SignIn', $route.query)"
          class="content-title text-purple cursor-pointer"
          v-text="$translations['sign-in'].title"
        />
      </div>
    </div>

    <div class="sign-up-form">
      <div class="sign-up-form-name">
        <InputTextField
          v-model="form.first_name"
          qa="sign_up_first_name"
          ref-name="sign_up_first_name"
          :label="$translations['sign-up'].labels.first_name"
          :lang="lang"
        />
        <InputTextField
          v-model="form.last_name"
          qa="sign_up_last_name"
          ref-name="sign_up_last_name"
          :label="$translations['sign-up'].labels.last_name"
          :lang="lang"
        />
      </div>
      <InputEmailField
        v-model="form.email"
        @onBlur="validateEmailUse"
        qa="sign_up_email"
        ref-name="sign_up_email"
        :label="$translations['sign-up'].labels.email"
        :error="Boolean(emailError)"
        :errorText="emailError"
        :lang="lang"
        type="email"
      />
      <PasswordField
        v-model="form.password"
        qa="sign_up_password"
        ref-name="sign_up_password"
        :label="$translations['sign-up'].labels.password"
        :lang="lang"
        :enabled-regex="true"
        regex="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])"
        @onError="verifyPassword($event)"
      />

      <PasswordValidation
        :password="form.password"
        :validationRegex="validationPassword"
      />

      <Button
        @clicked="signUp()"
        class="button"
        id="registro_ok"
        qa="sign_up_submit"
        :disable="formIsNotValid"
        :loading="loading"
        :text="$translations['sign-in']['sign-up-email']"
      />
    </div>

    <div class="divider">
      <div class="divider-line" />
      <div
        class="margin-x body-small"
        v-text="$translations['sign-in']['sign-in-provider']"
      />
      <div class="divider-line" />
    </div>

    <div class="social-buttons">
      <div v-for="(item, id) in providers" :key="id">
        <Button
          @clicked="socialLogin(item.id)"
          :type="item.type"
          :icon="item.icon"
          :svg-name="item.svg"
          :color="item.color"
          v-if="item.enabled"
        />
      </div>
    </div>

    <div
      class="content-title btn-link text-gray"
      @click="goEmployeeOnboarding"
      v-text="$translations['sign-up']['employee-link']"
    />

    <VersionComponent :app-version="$version" origin="user" />

    <div class="terms-and-conditions">
      <span
        class="small text-gray-50"
        v-html="$translations['sign-up'].terms[0]"
      />
      <span
        @click="openTermsAndConditions"
        class="link small"
        v-html="$translations['sign-up'].terms[1]"
      />
      <span
        class="small text-gray-50"
        v-html="$translations['sign-up'].terms[2]"
      />
      <span
        @click="openTermsAndConditions"
        class="link small"
        v-html="$translations['sign-up'].terms[3]"
      />
    </div>
  </div>
</template>

<script>
import {
  InputTextField,
  PasswordField,
  InputEmailField
} from '@seliaco/sea-otter'
import { Button, validateEmail, VersionComponent } from '@seliaco/red-panda'
import SignUpMixin from '@/mixins/sign-up-mixin'
import PasswordValidation from './components/PasswordValidation.vue'
import { Browser } from '@capacitor/browser'
import { Acquisition } from '@/types/events'

export default {
  name: 'SignUp',
  components: {
    InputTextField,
    PasswordField,
    InputEmailField,
    Button,
    PasswordValidation,
    VersionComponent
  },
  mixins: [SignUpMixin],
  data () {
    return {
      emailError: null,
      form: {
        firebase_uid: null,
        email: null,
        password: null,
        first_name: null,
        last_name: null
      },
      formError: {
        password: true,
        email: true
      },
      validationPassword: false
    }
  },
  mounted () {
    const url = this.$route.fullPath
    Acquisition.signUp({
      title: 'Selia | SignUp',
      url
    })

    const query = this.$route.query

    if (query.first_name) {
      this.form.first_name = query.first_name
    }

    if (query.last_name) {
      this.form.last_name = query.last_name
    }

    if (query.email) {
      this.form.email = query.email
    } else if (this.$store.getters?.form?.email) {
      this.form.email = this.$store.getters.form.email
    }

    if (query.company_code) {
      this.$router.replace({
        name: 'EmployeeOnboarding',
        query: this.$route.query
      })
    }

    if (this.$store.getters?.form?.password) {
      this.form.password = this.$store.getters.form.password
    }
  },
  methods: {
    async openTermsAndConditions () {
      await Browser.open({ url: process.env.VUE_APP_TERMS_AND_CONDITIONS_URL })
    },
    async validateEmailUse () {
      const unique = encodeURIComponent(this.form.email)
      const result = await this.$auth.verifyEmail(unique)
      this.emailError =
        this.form.email && !validateEmail(this.form.email)
          ? this.$translations['form-error'].email
          : !result.is_valid
          ? this.$translations['form-error'].duplicatemail
          : null
      this.formError.email = !validateEmail(this.form.email)
        ? true
        : !result.is_valid
    },
    handleError (field, value) {
      this.formError[field] = value
    },
    isPasswordValid (password) {
      const passwordRegex = new RegExp(
        '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$'
      )
      return passwordRegex.test(password)
    },
    verifyPassword (event) {
      this.validationPassword = event
    },
    goEmployeeOnboarding () {
      this.$router.push({
        name: 'EmployeeOnboarding',
        query: {
          ...this.$route.query
        }
      })
    }
  },
  computed: {
    formIsNotValid () {
      return Boolean(
        !this.form.email ||
          !this.form.password ||
          !this.form.first_name ||
          !this.form.last_name ||
          this.formError.email ||
          !this.form.password ||
          !this.isPasswordValid(this.form.password)
      )
    }
  }
}
</script>

<style lang="sass">
.sign-up
  display: flex
  flex-direction: column
  align-items: center
  gap: 24px
  width: 100%
  max-width: 480px
  &-logo
    display: none
  &-header
    display: flex
    flex-direction: column
    align-items: center
    gap: 16px
    &-continue
      display: flex
      gap: 8px
      margin-bottom: 16px
.sign-up-form
  display: flex
  flex-direction: column
  align-items: center
  width: 100%
  max-width: 480px
  gap: 8px
  &-name
    display: flex
    width: 100%
    gap: 8px
  .button
    margin-top: 8px
  .heading-display
    margin: 16px 0 40px
    text-align: center
  .forgot-password
    margin-top: 16px
    text-align: end
.social-buttons
  display: flex
  gap: 24px
.divider
  display: flex
  align-items: center
  width: 100%
  &-line
    min-width: 50px
    height: 1px
    width: 100%
    background: var(--gray-20)
  .body-small
    white-space: nowrap
    color: var(--gray-50)
.link
.btn-link
  color: var(--purple)
  cursor: pointer
.terms-and-conditions
  line-height: 1
  text-align: center

@media (min-width: 840px)
  .sign-up
    &-logo
      display: block
</style>
