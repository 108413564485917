<template>
  <PageContainer
    :title="title"
    :back="back"
    :action="actions.close"
    :ellipsis="true"
  >
    <template v-slot:content>
      <div
        v-if="isMounted"
        id="wrapper"
        :data-tf-widget="typeFormId"
        :data-tf-hidden="typeFormParams"
        data-tf-inline-on-mobile
        data-tf-medium="snippet"
      />
    </template>
  </PageContainer>
</template>

<script>
import { PageContainer } from '@seliaco/red-panda'

export default {
  name: 'Typeform',
  components: { PageContainer },
  mounted () {
    this.initSdk()
  },
  data () {
    return {
      title: 'Selia',
      typeFormId: '',
      typeFormParams: '',
      isMounted: false,
      back: {
        callback: () => {
          this.$router.back()
        }
      },
      actions: {
        close: {
          icon: 'icon-close',
          callback: () => {
            this.$router.push('/')
          }
        }
      }
    }
  },
  methods: {
    initSdk () {
      if (!this.$route.query.formId) {
        console.error('Form ID is not defined')

        return
      }

      this.typeFormId = this.$route.query.formId

      if (this.$route.query.title) {
        this.title = this.$route.query.title
      }

      const params = {
        ...this.$route.query,
        formId: null,
        title: null
      }

      delete params.formId
      delete params.title

      this.typeFormParams = this.objectToFormParams(this.$route.query)

      const script = document.createElement('script')
      script.src = '//embed.typeform.com/next/embed.js'
      window.document.body.appendChild(script)

      this.isMounted = true
    },
    objectToFormParams (obj) {
      const keyValuePairs = []

      for (const key in obj) {
        const value = obj[key]
        keyValuePairs.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      }

      return keyValuePairs.join(',')
    }
  }
}
</script>

<style scoped>
#wrapper {
  width: 100%;
  height: 100vh;
}

#wrapper * {
  margin: 0;
  padding: 0;
}

::v-deep iframe,
iframe {
  border-radius: 0 !important;
  outline: unset !important;
}
</style>
